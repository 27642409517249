import { NEW_DESCRIPTION_CHANGE, NEW_DESCRIPTION_CLEAR } from '../actions/types'

import { TEXT } from '../consts'

const INITIAL_STATE = {
    descriptionItemTypeId: TEXT,
    staticBlockId: null,
    bundleItemId: null,
    header: '',
    text: '',
    image1: -1,
    image2: -1,
    json: {
        cols: [],
        data: [],
        boldedRows: [],
        boldedCols: [],
    },
    notForAllegro: false,
    notForMagento: false,
    notForHurton: false,
    youtubeLink: '',
    hoverIndex: null,
    headerIcon: '',
    filterStaticBlocks: false,
}

const reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case NEW_DESCRIPTION_CHANGE:
            return {
                ...state,
                [action.name]: action.value,
            }

        case NEW_DESCRIPTION_CLEAR:
            return {
                ...INITIAL_STATE,
            }

        default:
            return state
    }
}

export default reducer
