import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
    Avatar,
    Checkbox,
    FormControl,
    FormControlLabel,
    ListItemAvatar,
    ListItemText,
    MenuItem,
    TextField,
    Button,
    Icon,
    Switch,
    Radio,
} from '@material-ui/core'
import ArrowUpIcon from '@material-ui/icons/ArrowUpwardRounded'
import ArrowDownIcon from '@material-ui/icons/ArrowDownwardRounded'
import classNames from 'classnames'

import Editor from '../../../../components/description/DescriptionEditor'
import {
    TEXT,
    STATIC_BLOCK,
    BUNDLE_ITEM,
    TABLE,
    IMAGE_TEXT,
    TEXT_IMAGE,
    YOUTUBE,
    LINK,
} from '../../../../consts'
import TableEditor from '../../../../components/TableEditor'
import ImageUpload from '../../../../components/ImageUpload'
import { SingleSelect } from '../../../../components/Select'
import { connect } from 'react-redux'
import { forEach } from 'lodash'

const styles = theme => ({
    control: {
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit * 2,
    },

    controlImage: {
        marginTop: theme.spacing.unit,
    },

    button: {
        margin: theme.spacing.unit,
        marginLeft: 0,
    },

    leftIcon: {
        marginRight: theme.spacing.unit,
    },

    avatarImgProps: {
        width: 'auto',
        height: 'auto',
    },

    avatar: {
        maxWidth: '40px',
        maxHeight: '40px',
        width: 'auto',
        height: 'auto',
        borderRadius: 0,
    },
})

class DescriptionForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            newLinkName: '',
            newLinkUrl: '',
        }
    }

    selectDescriptionItem = localId => {
        this.props.selectDescriptionItem(localId)
    }

    descriptionChange = (name, value) => {
        let descriptionItemTypeId = null
        const selectedDescriptionItem = this.props.description.find(
            item => item.localId === this.props.selectedItemLocalId
        )

        if (this.props.selectedItemLocalId !== null) {
            this.props.updateDescriptionItem(
                this.props.selectedItemLocalId,
                name,
                value
            )

            descriptionItemTypeId =
                selectedDescriptionItem.descriptionItemTypeId
        } else {
            this.props.newDescriptionChange(name, value)
            descriptionItemTypeId = this.props.newDescription
                .descriptionItemTypeId
        }

        this.changeType(name, value, descriptionItemTypeId)
    }

    onChangeNewLinkName = event => {
        this.setState({ newLinkName: event.target.value })
    }

    onChangeNewLinkUrl = event => {
        this.setState({ newLinkUrl: event.target.value })
    }

    changeType = (name, value, typeId) => {
        let newType = null

        if (
            name === 'image1' &&
            value === -1 &&
            [IMAGE_TEXT, TEXT_IMAGE].includes(typeId)
        ) {
            newType = TEXT
        } else if (name === 'image1' && value !== -1 && typeId === TEXT) {
            newType = IMAGE_TEXT
        }

        if (newType !== null) {
            this.descriptionChange('descriptionItemTypeId', newType)
        }
    }

    editorContentChange = value => {
        this.descriptionChange('text', value)
    }

    handleChange = event => {
        const { name, value } = event.target
        this.descriptionChange(name, value)
    }

    getSelectedItem = () => {
        let item = null

        if (this.props.selectedItemLocalId !== null) {
            item = this.props.description.find(
                item => item.localId === this.props.selectedItemLocalId
            )
        }

        return item
    }

    handleLinkChange = (event, name, index) => {
        if (index < 0) {
            return
        }

        let item = this.getSelectedItem()

        let text = ''

        if (item === null) {
            text = this.props.newDescription.text
        } else {
            text = item.text
        }

        const { value } = event.target
        const links = text
            .split('\n')
            .filter(text => text.length > 0)
            .filter(text => text.indexOf('|') !== -1)
            .map(text => {
                const [name, url] = text.split('|')
                return { name, url }
            })

        console.log(links)

        if (index >= links.length) {
            return
        }

        if (name == 'name') {
            links[index]['name'] = value
        } else if (name == 'url') {
            links[index]['url'] = value
        }

        const newText = links
            .map(({ name, url }) => `${name}|${url}`)
            .join('\n')
        this.descriptionChange('text', newText)
    }

    handleRemoveLink = index => {
        if (index < 0) {
            return
        }

        let item = this.getSelectedItem()
        let text = ''

        if (item === null) {
            text = this.props.newDescription.text
        } else {
            text = item.text
        }

        const links = text
            .split('\n')
            .filter(text => text.length > 0)
            .filter(text => text.indexOf('|') !== -1)
            .map(text => {
                const [name, url] = text.split('|')
                return { name, url }
            })

        if (index >= links.length) {
            return
        }

        links.splice(index, 1)
        const newText = links
            .map(({ name, url }) => `${name}|${url}`)
            .join('\n')
        this.descriptionChange('text', newText)
    }

    handleAddLink = (name, url) => {
        if (name.trim() == '' || url.trim() == '') {
            return
        }

        let item = this.getSelectedItem()
        let text = ''

        if (item === null) {
            text = this.props.newDescription.text
        } else {
            text = item.text
        }

        const links = text
            .split('\n')
            .filter(text => text.length > 0)
            .filter(text => text.indexOf('|') !== -1)
            .map(text => {
                const [name, url] = text.split('|')
                return { name, url }
            })

        links.push({ name, url })

        const newText = links
            .map(({ name, url }) => `${name}|${url}`)
            .join('\n')
        this.descriptionChange('text', newText)

        this.setState({ newLinkName: '', newLinkUrl: '' })
    }

    selectStaticBlock = value => {
        this.descriptionChange('staticBlockId', value)
    }

    handleCheckboxChange = event => {
        const { name, checked: value } = event.target
        this.descriptionChange(name, value)
    }

    getTemplateOptions = () => {
        return this.props.descriptionItemTypes.items.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
                {name}
            </MenuItem>
        ))
    }

    getImageOptions = () => {
        const { classes } = this.props
        const imageOptions = this.props.images.map(({ id, url }) => ({
            url,
            id,
        }))

        imageOptions.unshift({ url: 'Brak', id: -1 })

        return imageOptions.map(({ url, id }) => (
            <MenuItem key={id} value={id}>
                {id === -1 ? (
                    <ListItemText>{url}</ListItemText>
                ) : (
                    <ListItemAvatar>
                        <Avatar
                            className={classes.avatar}
                            imgProps={{
                                className: classes.avatarImgProps,
                            }}
                            src={url}
                        />
                    </ListItemAvatar>
                )}
            </MenuItem>
        ))
    }

    imageField = (imageId, name, label) => {
        const { classes } = this.props
        const imageOptions = this.getImageOptions()

        const image = this.props.images.find(item => item.id === imageId)

        imageId = image ? image.id : -1
        const isMain = image ? image.isMain : false
        const notForGallery = image ? image.notForGallery : false
        const disabled = [null, undefined, 0, -1].includes(imageId)

        return (
            <>
                <TextField
                    className={classes.controlImage}
                    fullWidth
                    label={label}
                    name={name}
                    onChange={this.handleChange}
                    variant="outlined"
                    value={imageId}
                    select
                >
                    {imageOptions}
                </TextField>
                <div>
                    <FormControlLabel
                        label="Zdjęcie główne"
                        control={
                            <Radio
                                className={classes.control}
                                checked={isMain}
                                disabled={disabled}
                                name="isMain"
                                color="primary"
                                onChange={this.props.setMainImage.bind(
                                    this,
                                    imageId,
                                    null
                                )}
                            />
                        }
                    />
                    <FormControlLabel
                        label="Nie wyświetlaj zdjęcia w galerii"
                        control={
                            <Checkbox
                                className={classes.control}
                                checked={notForGallery}
                                disabled={disabled}
                                name="notForGallery"
                                onChange={() =>
                                    this.props.toggleNotForGallery(
                                        imageId,
                                        null
                                    )
                                }
                            />
                        }
                    />
                </div>
            </>
        )
    }

    getStaticBlockOptions = () => {
        return this.props.staticBlocks.map(({ id, header }) => (
            <MenuItem key={id} value={id}>
                {header}
            </MenuItem>
        ))
    }

    getBundleItemOptions = () => {
        return this.props.bundleItems.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
                {name}
            </MenuItem>
        ))
    }

    removeDescriptionItem = () => {
        const localId = this.props.selectedItemLocalId
        this.props.removeDescriptionItem(localId)
    }

    saveNewDescriptionItem = () => {
        const { isDescription2 } = this.props

        const {
            bundleItemId,
            descriptionItemTypeId,
            header,
            image1,
            image2,
            json,
            notForAllegro,
            notForMagento,
            staticBlockId,
            text,
            youtubeLink,
            headerIcon,
        } = this.props.newDescription

        this.props.addDescriptionItem({
            bundleItemId,
            descriptionItemTypeId,
            header,
            image1,
            image2,
            json,
            notForAllegro,
            notForMagento,
            staticBlockId,
            text,
            youtubeLink,
            isDescription2,
            headerIcon,
        })

        this.props.clearNewDescription()
    }

    handleFile = data => {
        const { id, name, sku } = this.props.product
        this.props.uploadImage(id, sku, name, data)
    }

    renderButtons = () => {
        const { classes } = this.props
        const variant = 'contained'
        const selectedItemLocalId = this.props.selectedItemLocalId

        const sort = this.props.description.findIndex(
            item => item.localId === this.props.selectedItemLocalId
        )

        return (
            <div>
                {selectedItemLocalId === null ? (
                    <Button
                        className={classes.button}
                        color="primary"
                        variant={variant}
                        onClick={this.saveNewDescriptionItem}
                    >
                        <Icon className={classes.leftIcon}>save</Icon>
                        Zapisz blok
                    </Button>
                ) : (
                    <>
                        <Button
                            className={classes.button}
                            color="secondary"
                            variant={variant}
                            onClick={this.removeDescriptionItem}
                        >
                            <Icon className={classes.leftIcon}>delete</Icon>
                            Usuń
                        </Button>
                        <Button
                            className={classes.button}
                            variant={variant}
                            color="primary"
                            onClick={this.props.addNewDescriptionItem}
                        >
                            <Icon className={classes.leftIcon}>
                                add_circle_outline
                            </Icon>
                            Utwórz nowy blok
                        </Button>
                    </>
                )}
                <ImageUpload handleFile={this.handleFile} />
                {sort > 0 ? (
                    <Button
                        className={classes.button}
                        color="primary"
                        variant={variant}
                        onClick={() =>
                            this.props.reorderDescription(sort, sort - 1)
                        }
                    >
                        <ArrowUpIcon className={classes.leftIcon} />
                        Przesuń w górę
                    </Button>
                ) : (
                    <Button
                        className={classes.button}
                        color="primary"
                        variant={variant}
                        disabled
                    >
                        <ArrowUpIcon className={classes.leftIcon} />
                        Przesuń w górę
                    </Button>
                )}
                {sort !== -1 ? (
                    <Button
                        className={classes.button}
                        color="primary"
                        variant={variant}
                        onClick={() =>
                            this.props.reorderDescription(sort, sort + 1)
                        }
                    >
                        <ArrowDownIcon className={classes.leftIcon} />
                        Przesuń w dół
                    </Button>
                ) : (
                    <Button
                        className={classes.button}
                        color="primary"
                        variant={variant}
                        disabled
                    >
                        <ArrowDownIcon className={classes.leftIcon} />
                        Przesuń w dół
                    </Button>
                )}
            </div>
        )
    }

    render() {
        const { classes } = this.props

        let selectedDescriptionItem = null

        if (this.props.selectedItemLocalId !== null) {
            selectedDescriptionItem = this.props.description.find(
                item => item.localId === this.props.selectedItemLocalId
            )
        }

        let {
            descriptionItemTypeId,
            header,
            text,
            image1,
            image2,
            notForAllegro,
            notForMagento,
            notForHurton,
            staticBlockId,
            bundleItemId,
            json,
            youtubeLink,
            headerIcon,
            filterStaticBlocks,
        } = this.props.newDescription

        if (selectedDescriptionItem != null) {
            descriptionItemTypeId =
                selectedDescriptionItem.descriptionItemTypeId
            header = selectedDescriptionItem.header || ''
            text = selectedDescriptionItem.text || ''
            image1 =
                selectedDescriptionItem.image1 == null
                    ? null
                    : selectedDescriptionItem.image1
            image2 =
                selectedDescriptionItem.image2 == null
                    ? null
                    : selectedDescriptionItem.image2
            notForAllegro = selectedDescriptionItem.notForAllegro
            notForMagento = selectedDescriptionItem.notForMagento
            notForHurton = selectedDescriptionItem.notForHurton
            staticBlockId = selectedDescriptionItem.staticBlockId
            bundleItemId = selectedDescriptionItem.bundleItemId
            json = selectedDescriptionItem.json
            youtubeLink = selectedDescriptionItem.youtubeLink
            headerIcon = selectedDescriptionItem.headerIcon || ''
        }

        const templateOptions = this.getTemplateOptions()
        const staticBlockOptions = this.getStaticBlockOptions()
        const bundleItemOptions = this.getBundleItemOptions()

        let defaultTemplate = null

        if (templateOptions.length > 0) {
            defaultTemplate = templateOptions[0].value
        }

        let productAttributeMap = {}

        forEach(this.props.product.vertoAttributes, function({ id, value }) {
            productAttributeMap[id] = value
        })

        let staticBlocks = this.props.staticBlocks

        if (filterStaticBlocks) {
            staticBlocks = this.props.staticBlocks.filter(
                ({ vertoAttributes }) => {
                    for (
                        let i = 0, len = vertoAttributes.length;
                        i < len;
                        i++
                    ) {
                        const attribute = vertoAttributes[i]

                        if (
                            productAttributeMap[attribute.attributeId] ==
                            attribute.value
                        ) {
                            return true
                        }
                    }

                    return false
                }
            )
        }

        const blockOptions = staticBlocks.map(({ header, id }) => ({
            value: id,
            label: header,
        }))
        const staticBlock = blockOptions.find(
            ({ value }) => value == staticBlockId
        )

        const iconOptions = this.props.icons.map(({ url }) => (
            <MenuItem key={url} value={url}>
                <ListItemAvatar>
                    <Avatar
                        className={classes.avatar}
                        imgProps={{
                            className: classes.avatarImgProps,
                        }}
                        src={url}
                    />
                </ListItemAvatar>
            </MenuItem>
        ))

        iconOptions.unshift(
            <MenuItem key="none" value="">
                -- brak ikony --
            </MenuItem>
        )

        let links = []

        if (descriptionItemTypeId === LINK) {
            let temp = text
                .replaceAll('<p>', '')
                .replaceAll('</p>', '\n')
                .split('\n')
            links = temp
                .filter(text => text.length > 0)
                .filter(text => text.indexOf('|') !== -1)
                .map(text => {
                    const [name, url] = text.split('|')
                    return { name, url }
                })
                .filter(({ name, url }) => name.length > 0 && url.length > 0)
                .map(({ name, url }, index) => {
                    return (
                        <div key={index} className="flex">
                            <TextField
                                className={classNames(classes.control, 'mr-8')}
                                label={'nazwa'}
                                name={'links-name-' + index}
                                onChange={event =>
                                    this.handleLinkChange(event, 'name', index)
                                }
                                variant="outlined"
                                fullWidth
                                value={name}
                            />
                            <TextField
                                className={classNames(classes.control, 'mr-8')}
                                label={'adres'}
                                name={'links-url-' + index}
                                onChange={event =>
                                    this.handleLinkChange(event, 'url', index)
                                }
                                variant="outlined"
                                fullWidth
                                value={url}
                            />
                            <Button
                                className={classes.button}
                                color="secondary"
                                variant={'contained'}
                                onClick={() => this.handleRemoveLink(index)}
                            >
                                <Icon className={classes.leftIcon}>remove</Icon>
                            </Button>
                        </div>
                    )
                })

            links.push(
                <div key={'new'} className="flex">
                    <TextField
                        className={classNames(classes.control, 'mr-8')}
                        label={'nowa nazwa'}
                        name={'links-name-new'}
                        onChange={this.onChangeNewLinkName}
                        variant="outlined"
                        fullWidth
                        value={this.state.newLinkName}
                    />
                    <TextField
                        className={classNames(classes.control, 'mr-8')}
                        label={'nowy adres'}
                        name={'links-url-new'}
                        onChange={this.onChangeNewLinkUrl}
                        variant="outlined"
                        fullWidth
                        value={this.state.newLinkUrl}
                    />
                    <Button
                        className={classes.button}
                        color="primary"
                        variant={'contained'}
                        onClick={() =>
                            this.handleAddLink(
                                this.state.newLinkName,
                                this.state.newLinkUrl
                            )
                        }
                        disabled={
                            !this.state.newLinkName || !this.state.newLinkUrl
                        }
                    >
                        <Icon className={classes.leftIcon}>add</Icon>
                    </Button>
                </div>
            )
        }

        return (
            <div>
                <TextField
                    className={classes.control}
                    label="Szablon wyświetlania"
                    name="descriptionItemTypeId"
                    onChange={this.handleChange}
                    variant="outlined"
                    fullWidth
                    value={descriptionItemTypeId || defaultTemplate}
                    select
                >
                    {templateOptions}
                </TextField>
                {YOUTUBE === descriptionItemTypeId && (
                    <>
                        <TextField
                            className={classes.control}
                            label="Nazwa"
                            name="header"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                            value={header}
                        />
                        <TextField
                            className={classes.control}
                            label="Ikona w nagłówku (tylko na sklepie)"
                            name="headerIcon"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                            value={headerIcon}
                            select
                        >
                            {iconOptions}
                        </TextField>
                        <TextField
                            className={classes.control}
                            label="Link Youtube"
                            name="youtubeLink"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                            value={youtubeLink}
                        />
                    </>
                )}
                {LINK === descriptionItemTypeId && (
                    <>
                        <TextField
                            className={classes.control}
                            label="Nagłówek"
                            name="header"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                            value={header}
                        />
                        <TextField
                            className={classes.control}
                            label="Ikona w nagłówku (tylko na sklepie)"
                            name="headerIcon"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                            value={headerIcon}
                            select
                        >
                            {iconOptions}
                        </TextField>
                        {links}
                    </>
                )}
                {![YOUTUBE, STATIC_BLOCK, BUNDLE_ITEM, LINK].includes(
                    descriptionItemTypeId
                ) && (
                    <>
                        <TextField
                            className={classes.control}
                            label="Nagłówek"
                            name="header"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                            value={header}
                        />
                        <TextField
                            className={classes.control}
                            label="Ikona w nagłówku (tylko na sklepie)"
                            name="headerIcon"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                            value={headerIcon}
                            select
                        >
                            {iconOptions}
                        </TextField>
                    </>
                )}
                {![YOUTUBE, STATIC_BLOCK, BUNDLE_ITEM, TABLE, LINK].includes(
                    descriptionItemTypeId
                ) && (
                    <>
                        <FormControl className={classes.control} fullWidth>
                            <Editor
                                placeholder="Treść..."
                                value={text}
                                onChange={this.editorContentChange}
                                name="text"
                            />
                        </FormControl>
                        {this.imageField(image1, 'image1', 'Zdjęcie 1')}
                        {this.imageField(image2, 'image2', 'Zdjęcie 2')}
                    </>
                )}
                {descriptionItemTypeId === STATIC_BLOCK && (
                    <div
                        className="flex"
                        style={{ justifyContent: 'space-between' }}
                    >
                        <div style={{ flex: '4' }}>
                            <SingleSelect
                                className={classes.control}
                                label="Blok statyczny"
                                options={blockOptions}
                                helperText="Kliknij, aby wybrać lub wyszukać blok"
                                value={
                                    staticBlock === null ||
                                    staticBlock === undefined
                                        ? null
                                        : staticBlock
                                }
                                onChange={this.selectStaticBlock}
                            >
                                {staticBlockOptions}
                            </SingleSelect>
                        </div>
                        <div style={{ flex: '1' }}>
                            <FormControlLabel
                                style={{ marginLeft: 'auto', marginRight: 0 }}
                                control={
                                    <Checkbox
                                        checked={filterStaticBlocks}
                                        onChange={this.handleCheckboxChange}
                                        name="filterStaticBlocks"
                                    />
                                }
                                label="Zawęź"
                            />
                        </div>
                    </div>
                )}
                {descriptionItemTypeId === BUNDLE_ITEM && (
                    <>
                        <TextField
                            className={classes.control}
                            label="Element zestawu"
                            name="bundleItemId"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                            value={bundleItemId}
                            select
                        >
                            {bundleItemOptions}
                        </TextField>
                        <TextField
                            className={classes.control}
                            label="Ikona w nagłówku (tylko na sklepie)"
                            name="headerIcon"
                            onChange={this.handleChange}
                            variant="outlined"
                            fullWidth
                            value={headerIcon}
                            select
                        >
                            {iconOptions}
                        </TextField>
                    </>
                )}
                {descriptionItemTypeId === TABLE && (
                    <>
                        <TableEditor
                            data={json}
                            onChange={json =>
                                this.descriptionChange('json', json)
                            }
                        />
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={this.props.showAllegro}
                                    name="showAllegro"
                                    color="primary"
                                    onChange={this.props.toggleShowAllegro}
                                />
                            }
                            label="Pokaż na allegro"
                        />
                    </>
                )}
                {descriptionItemTypeId !== LINK && (
                    <>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={notForAllegro}
                                    onChange={this.handleCheckboxChange}
                                    name="notForAllegro"
                                />
                            }
                            label="Nie wyświetlaj bloku na Allegro"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={notForMagento}
                                    onChange={this.handleCheckboxChange}
                                    name="notForMagento"
                                />
                            }
                            label="Nie wyświetlaj bloku na Sklepie"
                        />
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={notForHurton}
                                    onChange={this.handleCheckboxChange}
                                    name="notForHurton"
                                />
                            }
                            label="Nie wyświetlaj bloku na Hurtonie"
                        />
                    </>
                )}
                {this.renderButtons()}
            </div>
        )
    }
}

const mapStateToProps = ({ presto }) => {
    return {
        icons: presto.icons.icons,
    }
}

export default withStyles(styles)(connect(mapStateToProps, {})(DescriptionForm))
