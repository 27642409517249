import {
    PRODUCT_ADD_IMAGE,
    PRODUCT_ADD_TITLE,
    PRODUCT_ATTRIBUTE_CHANGE,
    PRODUCT_FETCHING,
    PRODUCT_FETCHING_FAILURE,
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_FORM_TITLE_UPDATE,
    PRODUCT_FORM_UPDATE,
    PRODUCT_LIST_FETCHING,
    PRODUCT_LIST_FETCHING_FAILURE,
    PRODUCT_LIST_FETCHING_SUCCESS,
    PRODUCT_REMOVE_IMAGE,
    PRODUCT_REMOVE_TITLE,
    PRODUCT_SAVING_FAILURE,
    PRODUCT_SAVING_IN_PROGRESS,
    PRODUCT_SAVING_SUCCESS,
    PRODUCT_SET_MAIN_IMAGE,
    PRODUCT_SET_IMAGE_SORT,
    SWAP_BUNDLE_ITEM,
    TOGGLE_NOT_FOR_GALLERY,
    VERTO_PRODUCT_SEARCHING,
    VERTO_PRODUCT_SEARCHING_FAILURE,
    VERTO_PRODUCT_SEARCHING_SUCCESS,
    VERTO_PRODUCT_SEARCH_CLEAR,
    VERTO_PRODUCT_UPDATE_IN_PROGRESS,
    PRODUCT_ERRORS_FETCHING,
    PRODUCT_ERRORS_FETCHING_SUCCESS,
    PRODUCT_ERRORS_FETCHING_FAILURE,
    PRODUCT_ERRORS_CHANGE_FILTER,
    PRODUCT_HISTORY_FETCH,
    PRODUCT_CLONING_FAILURE,
    PRODUCT_CLONING_SUCCESS,
    PRODUCT_CLONING_IN_PROGRESS,
    PRODUCT_CLONING_FORM_OPEN,
    PRODUCT_CLONING_FORM_CLOSE,
    PRODUCT_AUCTIONS_FETCHING,
    PRODUCT_AUCTIONS_FETCHING_SUCCESS,
    PRODUCT_AUCTIONS_FETCHING_FAILURE,
    PURCHASE_PRICE_AND_ROTATION_FETCH_SUCCESS,
    PRODUCT_ATTRIBUTE_CUSTOM_VALUE_CHANGE,
    PRODUCT_SET_ALLEGRO_MINI,
    ADD_ERROR,
    PRODUCT_SET_HURTON_MINI,
} from '../actions/types'

import { objectToArray } from '../helpers'
import Api from '../Api'
import { redirect } from './RouterActions'

import _ from 'lodash'

export const productFetch = id => dispatch => {
    dispatch({ type: PRODUCT_FETCHING })

    Api.getProduct(id)
        .then(response => {
            dispatch({
                type: PRODUCT_FETCHING_SUCCESS,
                payload: response.data,
            })

            // if (response.data.categories) {
            //     response.data.categories.forEach(item => {
            //         importCategory(item.id)(dispatch)
            //     })
            // }

            //if (response.data.sku) {
            //    dispatch({ type: PRODUCT_AUCTIONS_FETCHING })
            //
            //    Api.getProductAuctions(response.data.sku)
            //        .then(response => {
            //            dispatch({
            //                type: PRODUCT_AUCTIONS_FETCHING_SUCCESS,
            //                payload: response.data,
            //            })
            //        })
            //        .catch(error => {
            //            dispatch({
            //                type: PRODUCT_AUCTIONS_FETCHING_FAILURE,
            //                error,
            //            })
            //        })
            //}
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_FETCHING_FAILURE,
                error,
            })
            console.error(error)
        })
}

export const importProduct = sku => dispatch => {
    Api.importProduct(sku)
        .then(response => {
            redirect(`product/${response.data.id}`)()
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_FETCHING_FAILURE,
                error,
            })
        })
}

export const searchProductFromVerto = sku => dispatch => {
    dispatch({ type: VERTO_PRODUCT_SEARCHING })

    Api.getProductFromVerto(sku)
        .then(response => {
            dispatch({
                type: VERTO_PRODUCT_SEARCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: VERTO_PRODUCT_SEARCHING_FAILURE,
                error,
            })
        })
}

export const updateProductFromVerto = sku => dispatch => {
    dispatch({ type: VERTO_PRODUCT_UPDATE_IN_PROGRESS })

    Api.getProductFromVerto(sku)
        .then(response => {
            dispatch({
                type: PRODUCT_SAVING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_SAVING_FAILURE,
                error,
            })
        })
}

export const clearSearchProductInVerto = () => dispatch => {
    dispatch({ type: VERTO_PRODUCT_SEARCH_CLEAR })
}

export const saveProduct = product => dispatch => {
    if (product.prestashopCategories.length < 2) {
        dispatch({
            type: ADD_ERROR,
            payload: [
                new Error('Nie ustawiono kategorii dla sklepu eltrox.pl'),
            ],
        })

        return
    }

    dispatch({ type: PRODUCT_SAVING_IN_PROGRESS })

    const description = product.description.map((item, index) => {
        let text = ''

        if (item.text) {
            text = item.text.trim().replace('<p><br></p>', '')
        }

        return {
            ...item,
            text,
            sort: index,
            image1: item.image1 !== -1 ? item.image1 : null,
            image2: item.image2 !== -1 ? item.image2 : null,
            headerIcon: item.headerIcon == '' ? null : item.headerIcon,
        }
    })

    const allAttributes = _.flatMap(product.categories, item => item.attributes)

    const attributes = product.attributes
        .filter(attribute => !!attribute)
        .map(attribute => {
            let definition = allAttributes.find(
                item => item.id === attribute.id
            )
            let value

            if (definition && definition.isRange) {
                value = _.has(attribute.value, 'from')
                    ? `${attribute.value.from} - ${attribute.value.to}`
                    : attribute.value
            } else {
                value = _.isArray(attribute.value)
                    ? attribute.value
                          .filter(value => ![null, undefined].includes(value))
                          .map(item => item.value)
                          .join('|')
                    : attribute.value
            }

            return {
                ...attribute,
                value,
            }
        })

    const data = {
        ...product,
        price: null, // blokada zmiany ceny z poziomu presto
        price2: null, // blokada zmiany ceny z poziomu presto
        attributes,
        categories: product.categories.map(({ id }) => id),
        bundleItems: product.bundleItems.map(({ id, quantity }) => {
            return { id, quantity }
        }),
        prestashopCategories: product.prestashopCategories.map(
            ({ categoryId }) => categoryId
        ),
        description,
        auctions: objectToArray(product.auctions, item => item),
    }

    delete data.productHistory

    Api.saveProduct(data)
        .then(response => {
            dispatch({
                type: PRODUCT_SAVING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_SAVING_FAILURE,
                payload: error,
            })
        })
}

export const updateForm = (name, value) => dispatch => {
    dispatch({
        type: PRODUCT_FORM_UPDATE,
        name,
        value,
    })
}

export const updateFormTitle = (value, index, titleId) => dispatch => {
    dispatch({
        type: PRODUCT_FORM_TITLE_UPDATE,
        titleId,
        value,
        index,
    })
}

export const removeTitle = (productId, titleId) => dispatch => {
    Api.removeProductTitle(productId, titleId).then(() => {
        dispatch({
            type: PRODUCT_REMOVE_TITLE,
            titleId,
        })
    })
}

export const addTitle = (productId, title) => dispatch => {
    Api.addProductTitle(productId, title).then(response => {
        dispatch({
            type: PRODUCT_ADD_TITLE,
            payload: response.data,
        })
    })
}

export const removeImage = (productId, imageId) => dispatch => {
    Api.removeProductImage(productId, imageId).then(() => {
        dispatch({
            type: PRODUCT_REMOVE_IMAGE,
            imageId,
        })
    })
}

export const addImage = (productId, url) => dispatch => {
    Api.addProductImage(productId, url).then(response => {
        dispatch({
            type: PRODUCT_ADD_IMAGE,
            payload: response.data,
        })
    })
}

export const uploadImage = (productId, sku, name, data) => dispatch => {
    const formData = new FormData()
    formData.append('myfile', data)

    Api.imageUpload(formData, sku, name).then(response => {
        const url = response.data.imageUrl

        Api.addProductImage(productId, url).then(response => {
            dispatch({
                type: PRODUCT_ADD_IMAGE,
                payload: response.data,
            })
        })
    })
}

export const setMainImage = (imageId, index) => dispatch => {
    dispatch({
        type: PRODUCT_SET_MAIN_IMAGE,
        imageId,
        index,
    })
}

export const setAllegroMini = (imageId, index) => dispatch => {
    dispatch({
        type: PRODUCT_SET_ALLEGRO_MINI,
        imageId,
        index,
    })
}

export const setHurtonMini = (imageId, index) => dispatch => {
    dispatch({
        type: PRODUCT_SET_HURTON_MINI,
        imageId,
        index,
    })
}

export const toggleNotForGallery = (imageId, index) => dispatch => {
    dispatch({
        type: TOGGLE_NOT_FOR_GALLERY,
        imageId,
        index,
    })
}

export const setImageSort = (imageId, sort) => dispatch => {
    sort = parseInt(sort)
    dispatch({
        type: PRODUCT_SET_IMAGE_SORT,
        imageId,
        sort,
    })
}

export const updateAttribute = (id, value) => dispatch => {
    dispatch({
        type: PRODUCT_ATTRIBUTE_CHANGE,
        id,
        value,
    })
}

export const updateAttributeCustomValue = (id, value) => dispatch => {
    dispatch({
        type: PRODUCT_ATTRIBUTE_CUSTOM_VALUE_CHANGE,
        id,
        value,
    })
}

export const findProductsBySkuAndName = (query, page, pageSize) => dispatch => {
    dispatch({ type: PRODUCT_LIST_FETCHING })

    Api.findProductsBySkuAndName(query, page, pageSize)
        .then(response => {
            dispatch({
                type: PRODUCT_LIST_FETCHING_SUCCESS,
                payload: response.data,
                query,
            })
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_LIST_FETCHING_FAILURE,
                error,
            })
        })
}

export const findProductsBySkuAndNameForList = (
    query,
    page = 1,
    pageSize = 10
) => dispatch => {
    dispatch({ type: PRODUCT_LIST_FETCHING })

    Api.findProductsBySkuAndNameForList(query, page, pageSize)
        .then(response => {
            dispatch({
                type: PRODUCT_LIST_FETCHING_SUCCESS,
                payload: response.data,
                query,
            })
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_LIST_FETCHING_FAILURE,
                error,
            })
        })
}

export const findProducts = data => dispatch => {
    dispatch({ type: PRODUCT_LIST_FETCHING })

    let filters = []

    if (data.name) {
        filters.push({
            filter: 'name',
            operator: 'like',
            value: data.name,
        })
    }

    if (data.sku) {
        filters.push({
            filter: 'sku',
            operator: 'eq',
            value: data.sku,
        })
    }

    Api.findProducts({ filters })
        .then(response => {
            dispatch({
                type: PRODUCT_LIST_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_LIST_FETCHING_FAILURE,
                error,
            })
        })
}

export const swapBundleItem = (productId, oldItemId, newItemId) => dispatch => {
    Api.swapBundleItem(productId, oldItemId, newItemId).then(response => {
        dispatch({
            type: SWAP_BUNDLE_ITEM,
            data: response.data,
            oldItemId,
            newItemId,
        })
    })
}

export const getProductsWithErrors = (
    errorType = 'DEFAULT',
    page = 1,
    pageSize = 50
) => dispatch => {
    dispatch({ type: PRODUCT_ERRORS_FETCHING })

    Api.getProductsWithErrors(errorType, page, pageSize)
        .then(response => {
            dispatch({
                type: PRODUCT_ERRORS_FETCHING_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_ERRORS_FETCHING_FAILURE,
                error,
            })
        })
}

export const changeProductWithErrorsFilter = filter => dispatch => {
    dispatch({ type: PRODUCT_ERRORS_CHANGE_FILTER, filter })
}

export const getProductHistory = (id, page = 1, pageSize = 10) => dispatch => {
    Api.getProductHistory(id, page, pageSize).then(response => {
        dispatch({
            type: PRODUCT_HISTORY_FETCH,
            data: response.data,
        })
    })
}

export const openCloneForm = () => dispatch => {
    dispatch({
        type: PRODUCT_CLONING_FORM_OPEN,
    })
}

export const closeCloneForm = () => dispatch => {
    dispatch({
        type: PRODUCT_CLONING_FORM_CLOSE,
    })
}

export const cloneProduct = (id, sku) => dispatch => {
    dispatch({
        type: PRODUCT_CLONING_IN_PROGRESS,
    })

    Api.cloneProduct(id, sku)
        .then(response => {
            dispatch({
                type: PRODUCT_CLONING_SUCCESS,
                payload: {
                    productId: response.data.id,
                },
            })
        })
        .catch(error => {
            dispatch({
                type: PRODUCT_CLONING_FAILURE,
                error,
            })
        })
}

export const getPurchasePriceAndRotation = sku => dispatch => {
    Api.getPurchasePriceAndRotation(sku)
        .then(response => {
            dispatch({
                type: PURCHASE_PRICE_AND_ROTATION_FETCH_SUCCESS,
                payload: response.data,
            })
        })
        .catch(error => {
            dispatch({
                type: 'ERROR',
                error,
            })
        })
}
