import React from 'react'
import { connect } from 'react-redux'
import {
    Tab,
    Tabs,
    Divider,
    Button,
    Icon,
    CircularProgress,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import MagentoIcon from '../../../components/MagentoIcon'
import VertoIcon from '../../../components/StreamsoftIcon'
import BaselinkerIcon from '../../../components/BaselinkerIcon'

import Page from '../../../components/Page'
import BasicInformation from './ProductBasicInformation'
import AllegroInformation from './AllegroInformation'
import Description from './description'
import CloneProductForm from './CloneProductForm.js'

import { getIcons } from '../../../actions/IconActions'
import { changeTab } from '../../../actions/TabsActions'
import { PRODUCT_TAB_CHANGE } from '../../../actions/types'

import { isLogged } from '../../../helpers'

const styles = theme => ({
    layoutRoot: {},

    layoutToolbar: {
        padding: 0,
    },

    tabsRoot: {
        height: 64,
        width: '100%',
    },

    tabRoot: {
        height: 64,
    },

    mainContent: {
        paddingLeft: 12,
        paddingRight: 12,
    },

    divider: {
        marginBottom: theme.spacing.unit * 2,
        marginTop: theme.spacing.unit * 3,
    },

    button: {
        margin: theme.spacing.unit,
        paddingLeft: theme.spacing.unit * 2,
        paddingRight: theme.spacing.unit * 2,
        marginLeft: 0,
    },

    leftIcon: {
        marginRight: theme.spacing.unit,
    },

    magentoIcon: {
        marginRight: theme.spacing.unit,
    },
})

class ProductForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            newTitle: '',
            isFindProductDialogOpen: false,
        }
    }

    componentDidMount() {
        const productId = this.props.match.params.productId

        const {
            productFetch,
            getCategoriesFromMagento,
            getDescriptionItemTypes,
            getDeliveryOptionTypes,
            getAllegroAccounts,
            getDeliveries,
            getAllStaticBlocks,
            getWarranties,
            getCategoriesFromPrestaShop,
            getProductCategoriesFromPrestaShop,
            getIcons,
        } = this.props

        if (isLogged() && productId) {
            productFetch(productId)
            getCategoriesFromMagento()
            getDescriptionItemTypes()
            getDeliveryOptionTypes()
            getAllegroAccounts()
            getDeliveries()
            getAllStaticBlocks()
            getWarranties()
            getCategoriesFromPrestaShop()
            getProductCategoriesFromPrestaShop(productId)
            getIcons()
        }
    }

    onTabChange = (event, value) => {
        this.props.changeTab(value)
    }

    handleTitleChange = (value, titleid, index) => {
        this.props.updateFormTitle(value, index, titleid)
    }

    handleAttributeChange = (event, { value, fid }) => {
        this.props.updateAttribute(fid, value)
    }

    removeTitle = (titleId, index) => {
        if (titleId === undefined) {
            titleId = null
        }

        if (index === undefined) {
            index = null
        }

        this.props.removeTitle(titleId, index)
    }

    removeCategory = categoryId => {
        this.props.removeCategoryFromProduct(categoryId)
    }

    toggleCollectionInPerson = () => {
        this.props.toggleCollectionInPerson()
    }

    toggleCollectionInPersonAfterPayment = () => {
        this.props.toggleCollectionInPersonAfterPayment()
    }

    saveProduct = () => {
        const { product } = this.props
        this.props.saveProduct(product)
    }

    updateProductFromVerto = () => {
        this.props.updateProductFromVerto(this.props.product.sku)
    }

    onBlur = () => {
        this.props.updateForm(
            'price',
            Number(this.props.product.price).toFixed(2)
        )
        this.props.updateForm(
            'price2',
            Number(this.props.product.price2).toFixed(2)
        )
    }

    addToMagento = () => {
        const { product } = this.props
        const { sku, images, magentoCategories: categories } = product
        const mainPhoto = images.find(item => item.isMain)

        const hasMainPhoto = ![null, undefined].includes(mainPhoto)
        const hasCategory =
            ![null, undefined].includes(categories) && categories.length > 0

        if (hasMainPhoto && hasCategory) {
            window.open(
                `http://myCi.eltrox.pl/verto_connect/add_or_update_product/${sku}?debug&upload_images=1`
            )
        } else {
            this.props.addError([
                {
                    message: 'Wybierz zdjęcie główne i kategorie magento',
                },
            ])
        }
    }

    updateInPrestaShop = () => {
        const { product } = this.props
        const { sku } = product

        const url = `https://tebim_pro@www.eltrox.pl/modules/psimportdesc/cron_simple.php?token=43ebf11e40&sku=${sku}`

        window.open(url)
    }

    updateInBaselinker = () => {
        const isBaselinkerLoading = this.props.baselinker.loading

        if (isBaselinkerLoading) {
            return
        }

        this.props.updateDescriptionInBaselinker(this.props.product.sku)
    }

    renderButtons = () => {
        const { classes } = this.props
        const isBaselinkerLoading = this.props.baselinker.loading

        return (
            <>
                <Button
                    className={classes.button}
                    size="small"
                    variant="contained"
                    onClick={this.saveProduct}
                    color="primary"
                >
                    <Icon fontSize="small" className={classes.leftIcon}>
                        save
                    </Icon>
                    Zapisz
                </Button>
                <Button
                    size="small"
                    className={classes.button}
                    variant="contained"
                    color="secondary"
                    onClick={this.updateProductFromVerto}
                >
                    <VertoIcon
                        fontSize="small"
                        className={classes.magentoIcon}
                    />
                    Pobierz dane z Verto
                </Button>
                <Button
                    size="small"
                    className={classes.button}
                    variant="contained"
                    onClick={this.addToMagento}
                    color="secondary"
                >
                    <MagentoIcon
                        fontSize="small"
                        className={classes.magentoIcon}
                    />
                    Aktualizuj w Magento
                </Button>
                <Button
                    size="small"
                    className={classes.button}
                    variant="contained"
                    onClick={this.updateInPrestaShop}
                    color="secondary"
                >
                    <Icon fontSize="small" className={classes.leftIcon}>
                        add_shopping_cart
                    </Icon>
                    Aktualizuj w PrestaShop
                </Button>
                <Button
                    size="small"
                    className={classes.button}
                    variant="contained"
                    onClick={this.updateInBaselinker}
                    color="secondary"
                >
                    {isBaselinkerLoading ? (
                        <CircularProgress
                            size={20}
                            className={classes.magentoIcon}
                        />
                    ) : (
                        <BaselinkerIcon
                            fontSize="small"
                            className={classes.magentoIcon}
                        />
                    )}
                    Aktualizauj opis w Baselinkerze
                </Button>
                <Button
                    size="small"
                    className={classes.button}
                    variant="contained"
                    onClick={this.props.openCloneForm}
                    color="secondary"
                >
                    <Icon fontSize="small" className={classes.leftIcon}>
                        file_copy
                    </Icon>
                    Klonuj
                </Button>
            </>
        )
    }

    render() {
        const { classes, fetching, tabIndex } = this.props
        const { name, id, ean, attributes } = this.props.product

        const header = this.renderButtons()

        let phrase = ''

        if (ean) {
            phrase = ean
        } else {
            const eanAttribute = attributes.find(attr => {
                if (Object.prototype.hasOwnProperty.call(attr, 'attribute')) {
                    return attr.attribute.name.toLowerCase() === 'ean'
                }

                return false
            })
            if (eanAttribute) {
                phrase = eanAttribute.value
            }
        }

        return (
            <Page
                loading={fetching}
                pageTitle={name}
                header={header}
                headerIcon={
                    <Icon className="text-32 mr-0 sm:mr-12">
                        shopping_basket
                    </Icon>
                }
                contentToolbar={
                    <Tabs
                        value={tabIndex}
                        onChange={this.onTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                        classes={{
                            root: classes.tabsRoot,
                        }}
                    >
                        <Tab
                            label="Dane podstawowe"
                            classes={{
                                root: classes.tabRoot,
                            }}
                        />
                        <Tab
                            label="Opis"
                            classes={{
                                root: classes.tabRoot,
                            }}
                        />
                        <Tab
                            label="Dodatkowy Opis"
                            classes={{
                                root: classes.tabRoot,
                            }}
                        />
                    </Tabs>
                }
                content={
                    <div>
                        <div className={classes.mainContent}>
                            {tabIndex === 0 && (
                                <BasicInformation
                                    addBundleItem={this.props.addBundleItem}
                                    addCategory={this.props.addCategory}
                                    addCategoryForEltrox={
                                        this.props.addCategoryForEltrox
                                    }
                                    addImage={this.props.addImage}
                                    bundleItemsSearch={
                                        this.props.bundleItemsSearch
                                    }
                                    changeBundleItemQuantity={
                                        this.props.changeBundleItemQuantity
                                    }
                                    findBundleItems={this.props.findBundleItems}
                                    handleChange={this.props.updateForm}
                                    magentoCategories={
                                        this.props.magentoCategories
                                    }
                                    magentoCategoriesForEltrox={
                                        this.props.magentoCategoriesForEltrox
                                    }
                                    product={this.props.product}
                                    removeBundleItem={
                                        this.props.removeBundleItem
                                    }
                                    removeCategory={this.props.removeCategory}
                                    removeCategoryForEltrox={
                                        this.props.removeCategoryForEltrox
                                    }
                                    removeImage={this.props.removeImage}
                                    setMainImage={this.props.setMainImage}
                                    swapBundleItem={this.props.swapBundleItem}
                                    toggleNotForGallery={
                                        this.props.toggleNotForGallery
                                    }
                                    setImageSort={this.props.setImageSort}
                                    setAllegroMini={this.props.setAllegroMini}
                                    setHurtonMini={this.props.setHurtonMini}
                                    uploadImage={this.props.uploadImage}
                                    warranties={this.props.warranties.items}
                                    getPurchasePriceAndRotation={
                                        this.props.getPurchasePriceAndRotation
                                    }
                                />
                            )}
                            {tabIndex === 1 && (
                                <Description
                                    addDescriptionItem={
                                        this.props.addDescriptionItem
                                    }
                                    bundleItems={this.props.product.bundleItems}
                                    clearNewDescription={
                                        this.props.clearNewDescription
                                    }
                                    description={this.props.product.description.filter(
                                        item => !item.isDescription2
                                    )}
                                    descriptionItemTypes={
                                        this.props.descriptionItemTypes
                                    }
                                    images={this.props.product.images}
                                    newDescription={this.props.newDescription}
                                    newDescriptionChange={
                                        this.props.newDescriptionChange
                                    }
                                    product={this.props.product}
                                    removeDescriptionItem={
                                        this.props.removeDescriptionItem
                                    }
                                    reorderDescription={
                                        this.props.reorderDescription
                                    }
                                    setMainImage={this.props.setMainImage}
                                    staticBlocks={this.props.staticBlocks}
                                    toggleNotForGallery={
                                        this.props.toggleNotForGallery
                                    }
                                    updateDescriptionItem={
                                        this.props.updateDescription
                                    }
                                    uploadImage={this.props.uploadImage}
                                    isDescription2={false}
                                />
                            )}
                            {tabIndex === 2 && (
                                <Description
                                    addDescriptionItem={
                                        this.props.addDescriptionItem
                                    }
                                    bundleItems={this.props.product.bundleItems}
                                    clearNewDescription={
                                        this.props.clearNewDescription
                                    }
                                    description={this.props.product.description.filter(
                                        item => item.isDescription2
                                    )}
                                    descriptionItemTypes={
                                        this.props.descriptionItemTypes
                                    }
                                    images={this.props.product.images}
                                    newDescription={this.props.newDescription}
                                    newDescriptionChange={
                                        this.props.newDescriptionChange
                                    }
                                    product={this.props.product}
                                    removeDescriptionItem={
                                        this.props.removeDescriptionItem
                                    }
                                    reorderDescription={
                                        this.props.reorderDescription
                                    }
                                    setMainImage={this.props.setMainImage}
                                    staticBlocks={this.props.staticBlocks}
                                    toggleNotForGallery={
                                        this.props.toggleNotForGallery
                                    }
                                    updateDescriptionItem={
                                        this.props.updateDescription
                                    }
                                    uploadImage={this.props.uploadImage}
                                    isDescription2={true}
                                />
                            )}
                            {tabIndex === 3 && (
                                <AllegroInformation
                                    accounts={this.props.accounts.items}
                                    addTitle={this.props.addTitle}
                                    categories={this.props.categories}
                                    deliveries={this.props.deliveries}
                                    handleChange={this.props.updateForm}
                                    handleTitleChange={this.handleTitleChange}
                                    importCategory={this.props.importCategory}
                                    product={this.props.product}
                                    removeCategoryFromProduct={
                                        this.props.removeCategoryFromProduct
                                    }
                                    addCategoryToProduct={
                                        this.props.addCategoryToProduct
                                    }
                                    removeTitle={this.props.removeTitle}
                                    searchCategories={
                                        this.props.searchCategories
                                    }
                                    updateAttribute={this.props.updateAttribute}
                                    saveAuctionToAllegro={
                                        this.props.saveAuctionToAllegro
                                    }
                                    stopAuction={this.props.stopAuction}
                                    removeAuction={this.props.removeAuction}
                                    activateAuction={this.props.activateAuction}
                                    updateAuction={this.props.updateAuction}
                                    saveAuction={this.props.saveAuction}
                                    addAuction={this.props.addAuction}
                                    getAttribute={this.props.getAttribute}
                                    attribute={this.props.attribute}
                                    addAttributeMapping={
                                        this.props.addAttributeMapping
                                    }
                                    removeAttributeMapping={
                                        this.props.removeAttributeMapping
                                    }
                                    addEmphasize={this.props.addEmphasize}
                                    removeEmphasize={this.props.removeEmphasize}
                                />
                            )}
                        </div>
                        <CloneProductForm sourceId={id} />
                        <Divider className={classes.divider} />
                        {this.renderButtons()}
                    </div>
                }
            />
        )
    }
}

const mapStateToProps = state => {
    return {
        tabIndex: state.presto.tabs.productTab,
        baselinker: state.presto.baselinker,
    }
}

export default withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, {
        changeTab: index => changeTab(PRODUCT_TAB_CHANGE, index),
        getIcons,
    })(ProductForm)
)
