import { connect } from 'react-redux'
import ProductForm from './components/ProductForm'
import {
    addImage,
    addTitle,
    getProductHistory,
    openCloneForm,
    productFetch,
    removeImage,
    removeTitle,
    saveProduct,
    setMainImage,
    setAllegroMini,
    setHurtonMini,
    swapBundleItem,
    toggleNotForGallery,
    setImageSort,
    updateAttribute,
    updateForm,
    updateFormTitle,
    updateProductFromVerto,
    uploadImage,
    getPurchasePriceAndRotation,
} from '../../actions/ProductActions'

import { updateDescriptionInBaselinker } from '../../actions/BaselinkerActions'

import {
    addBundleItem,
    removeBundleItem,
    findBundleItems,
    changeBundleItemQuantity,
} from '../../actions/BundleItemActions'

import {
    updateDeliveryFirstPiece,
    updateDeliveryNextPieces,
    updateDeliveryQuantity,
} from '../../actions/product/DeliveryActions'

import { getDescriptionItemTypes } from '../../actions/DescriptionItemTypeActions'

import { getDeliveryOptionTypes } from '../../actions/DeliveryOptionTypeActions'

import { getWarranties } from '../../actions/WarrantiesActions'

import { searchCategories, importCategory } from '../../actions/CategoryActions'

import { getAllegroAccounts } from '../../actions/AllegroAccountActions'

import {
    activateAuction,
    removeAuction,
    saveAuction,
    addAuction,
    saveAuctionToAllegro,
    stopAuction,
    updateAuction,
    addEmphasize,
    removeEmphasize,
} from '../../actions/product/AuctionActions'

import {
    addCategoryToProduct,
    removeCategoryFromProduct,
} from '../../actions/product/CategoryActions'

import { getDeliveries } from '../../actions/DeliveryActions'

import {
    addDescriptionItem,
    removeDescriptionItem,
    reorderDescription,
    updateDescription,
} from '../../actions/DescriptionActions'

import {
    getStaticBlocks,
    getAllStaticBlocks,
} from '../../actions/StaticBlockActions'

import {
    changeNewDescription,
    clearNewDescription,
} from '../../actions/NewDescriptionActions'

import {
    getAttribute,
    addAttributeMapping,
    removeAttributeMapping,
} from '../../actions/AttributesActions'

import {
    getCategoriesFromMagento,
    changeMagentoCategories,
    changeMagentoCategoriesForEltrox,
} from '../../actions/MagentoCategoriesActions'

import {
    getCategoriesFromPrestaShop,
    getProductCategoriesFromPrestaShop,
    updateProductCategoriesInPrestaShop,
} from '../../actions/product/PrestaShopCategoryActions'

import {
    addCategory,
    removeCategory,
    addCategoryForEltrox,
    removeCategoryForEltrox,
} from '../../actions/product/MagentoCategoryActions'

import { addError } from '../../actions/ErrorActions'

const mapStateToProps = state => {
    const {
        allegroAccounts: accounts,
        attribute,
        bundleItemsSearch,
        categories: searchCategories,
        deliveries,
        deliveryOptionTypes,
        descriptionItemTypes,
        newDescription,
        warranties,
        magentoCategories,
        magentoCategoriesForEltrox,
    } = state.presto

    const {
        attributes,
        auctions,
        bundleItems,
        categories,
        data,
        delivery,
        description,
        images,
        productHistory,
        staticBlocks,
        titles,
        vertoAttributes,
        prestashopCategories,
    } = state.presto.product

    const productData = {
        ...data.product,
        attributes,
        auctions,
        bundleItems,
        categories,
        delivery,
        description,
        images,
        productHistory,
        magentoCategories: state.presto.product.magentoCategories,
        magentoCategoriesPerStore:
            state.presto.product.magentoCategoriesPerStore,
        prestashopCategories,
        titles,
        vertoAttributes,
    }

    const fetching =
        data.fetching ||
        accounts.fetching ||
        deliveries.fetching ||
        deliveryOptionTypes.fetching ||
        descriptionItemTypes.fetching ||
        staticBlocks.fetching ||
        warranties.fetching

    return {
        accounts: accounts,
        attribute,
        bundleItemsSearch,
        categories: searchCategories,
        deliveries,
        deliveryOptionTypes,
        descriptionItemTypes,
        fetching,
        newDescription,
        magentoCategories,
        magentoCategoriesForEltrox,
        product: productData,
        staticBlocks,
        warranties,
    }
}

const Product = connect(mapStateToProps, {
    activateAuction,
    addAttributeMapping,
    addAuction,
    addBundleItem,
    addCategory,
    addCategoryForEltrox,
    addCategoryToProduct,
    addDescriptionItem,
    addError,
    addImage,
    addTitle,
    changeBundleItemQuantity,
    changeMagentoCategories,
    changeMagentoCategoriesForEltrox,
    clearNewDescription,
    findBundleItems,
    getAllStaticBlocks,
    getAllegroAccounts,
    getAttribute,
    getCategoriesFromMagento,
    getDeliveries,
    getDeliveryOptionTypes,
    getDescriptionItemTypes,
    getProductHistory,
    getStaticBlocks,
    getWarranties,
    importCategory,
    newDescriptionChange: changeNewDescription,
    openCloneForm,
    productFetch,
    removeAttributeMapping,
    removeAuction,
    removeBundleItem,
    removeCategory,
    removeCategoryForEltrox,
    removeCategoryFromProduct,
    removeDescriptionItem,
    removeImage,
    removeTitle,
    reorderDescription,
    saveAuction,
    saveAuctionToAllegro,
    saveProduct,
    searchCategories,
    setMainImage,
    setAllegroMini,
    setHurtonMini,
    setImageSort,
    stopAuction,
    swapBundleItem,
    toggleNotForGallery,
    updateAttribute,
    updateAuction,
    updateDeliveryFirstPiece,
    updateDeliveryNextPieces,
    updateDeliveryQuantity,
    updateDescription,
    updateForm,
    updateFormTitle,
    updateProductFromVerto,
    uploadImage,
    addEmphasize,
    removeEmphasize,
    getPurchasePriceAndRotation,
    updateDescriptionInBaselinker,
    getCategoriesFromPrestaShop,
    getProductCategoriesFromPrestaShop,
    updateProductCategoriesInPrestaShop,
})(ProductForm)

export default Product
