import {
    PRODUCT_FETCHING_SUCCESS,
    PRODUCT_SAVING_SUCCESS,
    PRODUCT_DESCRIPTION_UPDATE,
    PRODUCT_ADD_DESCRIPTION_ITEM,
    PRODUCT_REMOVE_DESCRIPTION_ITEM,
    PRODUCT_REORDER_DESCRIPTION,
    SWAP_BUNDLE_ITEM,
    PRODUCT_REMOVE_IMAGE,
} from '../../actions/types'

import { TEXT_IMAGE, IMAGE_TEXT, IMAGE_BOTTOM_TEXT, TEXT } from '../../consts'

import { v4 as uuid } from 'uuid'

const INITIAL_STATE = []

const reducer = (state = INITIAL_STATE, action) => {
    let description = state.slice()

    switch (action.type) {
        case PRODUCT_FETCHING_SUCCESS:
        case PRODUCT_SAVING_SUCCESS:
            return prepareDescription(action.payload)

        case PRODUCT_DESCRIPTION_UPDATE:
            return state.map(item => {
                if (item.localId === action.localId) {
                    return {
                        ...item,
                        [action.name]: action.value,
                    }
                }

                return item
            })

        case PRODUCT_ADD_DESCRIPTION_ITEM:
            description.push({
                ...action.descriptionItem,
                sort: description.length,
                localId: uuid(),
            })

            return description

        case PRODUCT_REMOVE_DESCRIPTION_ITEM:
            return description.filter(item => item.localId !== action.localId)

        case PRODUCT_REORDER_DESCRIPTION:
            const [removed] = description.splice(action.oldIndex, 1)
            description.splice(action.newIndex, 0, removed)

            return description.map((item, index) => ({
                ...item,
                sort: index,
            }))

        case SWAP_BUNDLE_ITEM:
            return state.map(item => {
                if (item.bundleItemId === action.oldItemId) {
                    return {
                        ...item,
                        bundleItemId: action.newItemId,
                    }
                }

                return item
            })

        case PRODUCT_REMOVE_IMAGE:
            return state.map(item => {
                const newItem = {
                    ...item,
                }

                let pushNewItem = false

                if (item.image1 === action.imageId) {
                    pushNewItem = true
                    newItem.image1 = null

                    if (
                        [TEXT_IMAGE, IMAGE_TEXT, IMAGE_BOTTOM_TEXT].includes(
                            item.descriptionItemTypeId
                        )
                    ) {
                        newItem.descriptionItemTypeId = TEXT
                    }
                }

                if (item.image2 === action.imageId) {
                    pushNewItem = true
                    newItem.image2 = null
                }

                if (pushNewItem) {
                    return newItem
                }

                return item
            })

        default:
            return state
    }
}

const sortDescriptionItems = items => {
    return items
        .slice()
        .sort((a, b) => a.sort - b.sort)
        .map((item, index) => ({
            ...item,
            sort: index,
        }))
}

const prepareDescription = ({ description }) => {
    if (!description) {
        return []
    }

    const sortedDescription = sortDescriptionItems(
        description.map(
            ({
                descriptionItemType,
                notForAllegro,
                notForMagento,
                notForHurton,
                header,
                id,
                image1,
                image2,
                isDescription2,
                json,
                sort,
                text,
                staticBlock,
                staticBlockId,
                bundleItemId,
                youtubeLink,
                headerIcon,
            }) => {
                if (!json || json === {}) {
                    json = {
                        cols: [],
                        data: [],
                        boldedRows: [],
                        boldedCols: [],
                        boldedCells: [],
                    }
                } else {
                    if (!json.hasOwnProperty('boldedCells')) {
                        json.boldedCells = []
                    }

                    if (!json.hasOwnProperty('cols')) {
                        json.cols = []
                    }

                    if (!json.hasOwnProperty('data')) {
                        json.data = []
                    }

                    const numberOfColumns = json.cols.length
                    const numberOfRows = json.data.length

                    if (!json.hasOwnProperty('boldedRows')) {
                        json.boldedRows = []
                    } else {
                        json.boldedRows.forEach(row => {
                            for (let i = 0; i < numberOfColumns; i++) {
                                json.boldedCells.push({
                                    row,
                                    col: i,
                                })
                            }
                        })
                    }

                    if (!json.hasOwnProperty('boldedCols')) {
                        json.boldedCols = []
                    } else {
                        json.boldedCols.forEach(col => {
                            for (let i = 0; i < numberOfRows; i++) {
                                json.boldedCells.push({
                                    row: i,
                                    col,
                                })
                            }
                        })
                    }
                }

                return {
                    descriptionItemTypeId: descriptionItemType.id,
                    notForAllegro,
                    notForMagento,
                    notForHurton,
                    header,
                    id,
                    image1: image1 ? image1.id : null,
                    image2: image2 ? image2.id : null,
                    isDescription2,
                    json,
                    sort: Number(sort),
                    text,
                    staticBlock,
                    staticBlockId,
                    bundleItemId,
                    youtubeLink,
                    localId: uuid(),
                    headerIcon,
                }
            }
        )
    )

    return sortedDescription
}

export default reducer
